import { IGetInfrastructureV3ResponseSchema } from 'shared/common/repositories/infrastructure-caching/entities/get_infrastructure/GetInfrastructureV3ResponseSchema';
import { transformFutureRoads } from 'shared/mf-infrastructure/mappers/transformGetInfrastructureV3Response/transformFutureRoads';
import { transformTransportAccessibilityInfrastructure } from 'shared/mf-infrastructure/mappers/transformGetInfrastructureV3Response/transformTransportAccessibilityInfrastructure';

export const transformNewbuildingInfrastructure = (result: IGetInfrastructureV3ResponseSchema) => {
  const transportAccessibilityInfrastructure = transformTransportAccessibilityInfrastructure(
    result.transportAccessibility,
  );

  const existingInfrastructure = result.infrastructure.items;
  const futureInfrastructure = result.future?.infrastructure || [];
  const futureRoads = transformFutureRoads(result.future?.roads);

  const infrastructure = [...existingInfrastructure, ...futureInfrastructure, ...futureRoads];

  return {
    regularInfrastructureItems: infrastructure,
    transportAccessibilityInfrastructure,
  };
};
