import {
  fetchGetInfrastructure,
  IGetInfrastructureRequest,
  IGetInfrastructureResponse,
} from 'shared/common/repositories/infrastructure-caching/v3/get-infrastructure';
import { restApiFunctionWrapper } from 'shared/common/utils/restApiWrapper/restApiWrapper';

export const fetchInfrastructure = restApiFunctionWrapper<IGetInfrastructureRequest, IGetInfrastructureResponse>(
  fetchGetInfrastructure,
);
