import { configureStore } from '@reduxjs/toolkit';

import {
  infrastructureSlice,
  mapBoundsSlice,
  pageSlice,
  panoramaSlice,
  paramsSlice,
  similarNewbuildingsSlice,
  transportAccessibilitySlice,
} from 'shared/mf-infrastructure/slices';
import { similarObjectsSlice } from 'shared/mf-infrastructure/slices/similarObjectsSlice';
import { IApplicationContext } from 'shared/mf-infrastructure/types/applicationContext';
import { IPreloadedState } from 'shared/mf-infrastructure/types/redux';

/**
 * Хелпер для создания Redux стора
 * @param context IApplicationContext - контекст приложения
 * @param preloadedState IPreloadedState - предзагруженное состояние
 * @param debugEnabled boolean - флаг доступности Redux Devtools на деве
 */
export const createReduxStore = (
  context: IApplicationContext,
  preloadedState: IPreloadedState,
  debugEnabled: boolean,
) => {
  const projectName = context.config.getStrict<string>('projectName');

  return configureStore({
    preloadedState,
    reducer: {
      [panoramaSlice.name]: panoramaSlice.reducer,
      [paramsSlice.name]: paramsSlice.reducer,
      [pageSlice.name]: pageSlice.reducer,
      [infrastructureSlice.name]: infrastructureSlice.reducer,
      [transportAccessibilitySlice.name]: transportAccessibilitySlice.reducer,
      [mapBoundsSlice.name]: mapBoundsSlice.reducer,
      [similarObjectsSlice.name]: similarObjectsSlice.reducer,
      [similarNewbuildingsSlice.name]: similarNewbuildingsSlice.reducer,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: context,
        },
      }),
    devTools: debugEnabled ? { name: projectName } : false,
  });
};
