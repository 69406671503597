import { Map } from '@cian/frontend-newbuilding-map-component';
import { useCallback } from 'react';

import { POSSIBLE_INSIDE_INFRASTRUCTURE_TYPES } from 'shared/mf-infrastructure/constants/transportAccessibilityInfrastructure';
import { ICoordinates } from 'shared/mf-infrastructure/types/map';
import {
  EInsideTAInfrastructureType,
  TTAInfrastructureType,
} from 'shared/mf-infrastructure/types/transportAccessibility';

type TSetTransportAccessibilityZoomResult = {
  isZoomChanged: boolean;
  animationDelay: number;
};

const INSIDE_TAB_ZOOM = 17;
const OUTSIDE_TAB_ZOOM = 15;
const ZOOM_ANIMATION_DELAY = 250;

export const useTransportAccessibilityZoom = (mapApi: Map | null, pinCoordinates: ICoordinates) => {
  const setTransportAccessibilityZoom = useCallback(
    (infrastructureType: TTAInfrastructureType | null): TSetTransportAccessibilityZoomResult => {
      if (!pinCoordinates || !infrastructureType || !mapApi) {
        return {
          isZoomChanged: false,
          animationDelay: 0,
        };
      }

      const zoom = POSSIBLE_INSIDE_INFRASTRUCTURE_TYPES.includes(infrastructureType as EInsideTAInfrastructureType)
        ? INSIDE_TAB_ZOOM
        : OUTSIDE_TAB_ZOOM;

      const currentZoom = mapApi.getZoom();

      mapApi.setCenterAndZoom(pinCoordinates, zoom, ZOOM_ANIMATION_DELAY);

      return {
        isZoomChanged: currentZoom !== zoom,
        animationDelay: ZOOM_ANIMATION_DELAY,
      };
    },
    [pinCoordinates, mapApi],
  );

  return {
    setTransportAccessibilityZoom,
  };
};
