import { IGeoObject } from '@cian/frontend-newbuilding-map-component/es/types';

import { IGetInfrastructureV3ResponseSchema } from 'shared/common/repositories/infrastructure-caching/entities/get_infrastructure/GetInfrastructureV3ResponseSchema';
import { transformFutureRoads } from 'shared/mf-infrastructure/mappers/transformGetInfrastructureV3Response/transformFutureRoads';
import { transformResultToMainGeoObjects } from 'shared/mf-infrastructure/mappers/transformGetInfrastructureV3Response/transformResultToMainGeoObjects';
import { getInfrastructureMapByYear } from 'shared/mf-infrastructure/utils/getInfrastructureMapByYear';
import { toGeoObjects } from 'shared/mf-infrastructure/utils/toGeoObjects';

export function transformResultToGeoObjects(result: IGetInfrastructureV3ResponseSchema): IGeoObject[] {
  const mainObjects = transformResultToMainGeoObjects(result);

  const existingInfrastructure = result.infrastructure.items;
  const futureInfrastructure = result.future?.infrastructure || [];
  const futureRoads = transformFutureRoads(result.future?.roads);

  const infrastructure = [...existingInfrastructure, ...futureInfrastructure, ...futureRoads];

  return toGeoObjects(
    getInfrastructureMapByYear(infrastructure, mainObjects, futureInfrastructure.length > 0),
    new Date().getFullYear(),
  );
}
