import { createSelector } from '@reduxjs/toolkit';

import { transformSimilarObjectsToInfrastructureItems } from 'shared/mf-infrastructure/mappers/transformGetAjaxMapRoundaboutResponse';
import { transformFutureRoads } from 'shared/mf-infrastructure/mappers/transformGetInfrastructureV3Response/transformFutureRoads';
import { transformSimilarNewbuildingsToInfrastructureItems } from 'shared/mf-infrastructure/mappers/transformGetRecommendationsNearNewbuildingResponse';
import { selectSimilarObjectsApiResult } from 'shared/mf-infrastructure/selectors/similarObjects';
import { IInfrastructureData } from 'shared/mf-infrastructure/types/infrastructure';
import { getInfrastructureMapByYear } from 'shared/mf-infrastructure/utils/getInfrastructureMapByYear';

import { selectInfrastructureApiResult } from './selectInfrastructureApiResult';
import { selectMainGeoObjects } from './selectMainGeoObjects';
import { selectSimilarNewbuildingsApiResult } from '../similarNewbuildings';

export const selectInfrastructureGeoObjectsByYearMap = createSelector(
  selectInfrastructureApiResult,
  selectSimilarObjectsApiResult,
  selectSimilarNewbuildingsApiResult,
  selectMainGeoObjects,
  (
    infrastructureResult,
    similarObjectsResult,
    similarNewbuildingsResult,
    mainGeoObjects,
  ): Map<number, IInfrastructureData> => {
    const existingInfrastructure = infrastructureResult?.infrastructure.items || [];
    const futureInfrastructure = infrastructureResult?.future?.infrastructure || [];
    const futureRoads = transformFutureRoads(infrastructureResult?.future?.roads);
    const similarObjects = similarObjectsResult
      ? transformSimilarObjectsToInfrastructureItems(similarObjectsResult)
      : [];
    const similarNewbuildings = similarNewbuildingsResult
      ? transformSimilarNewbuildingsToInfrastructureItems(similarNewbuildingsResult)
      : [];

    const infrastructure = [
      ...existingInfrastructure,
      ...futureInfrastructure,
      ...futureRoads,
      ...similarObjects,
      ...similarNewbuildings,
    ];

    return getInfrastructureMapByYear(infrastructure, mainGeoObjects, futureInfrastructure.length > 0);
  },
);
