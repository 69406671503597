import { ValidationError } from '@cian/peperrors/shared';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { Err } from 'shared/common/errors';
import { IGetInfrastructureResponse } from 'shared/common/repositories/infrastructure-caching/v3/get-infrastructure';
import { selectParams } from 'shared/mf-infrastructure/selectors/params/selectParams';
import { fetchInfrastructure } from 'shared/mf-infrastructure/services/fetchInfrastructure/fetchInfrastructure';
import { IThunkApi } from 'shared/mf-infrastructure/types/redux';

/* istanbul ignore next */
export const loadInfrastructure = createAsyncThunk<IGetInfrastructureResponse, string | undefined, IThunkApi>(
  'infrastructure/loadData',
  async (profile, thunkApi) => {
    const { rejectWithValue, extra, getState } = thunkApi;
    const state = getState();
    const params = selectParams(state);

    if (params === null) {
      return rejectWithValue(
        new ValidationError({
          message: 'в состояние отсутсвуют параметры запроса',
          domain: 'thunks/loadInfrastructure',
        }),
      );
    }

    const infrastructure = await fetchInfrastructure(extra, { ...params, profile });

    if (Err.is(infrastructure)) {
      return rejectWithValue(infrastructure.Err);
    }

    return infrastructure.Ok;
  },
  {
    condition: (_, thunkApi) => {
      const { getState } = thunkApi;

      const state = getState();

      return state.infrastructure.status !== 'pending';
    },
  },
);
