import { IMapGeoObject } from '@cian/frontend-newbuilding-map-component';

import { IGetInfrastructureV3ResponseSchema } from 'shared/common/repositories/infrastructure-caching/entities/get_infrastructure/GetInfrastructureV3ResponseSchema';
import { TAsyncThunkStatus } from 'shared/mf-infrastructure/types/redux';
import { ITAInfrastructure } from 'shared/mf-infrastructure/types/transportAccessibility';

import { IFutureInfrastructureItem, IFutureRoad, TFutureRoad } from './futureInfrastructure';
import { ICoordinates } from './map';

export interface IInfrastructureType {
  /** Название типа для блока инфраструктуры **/
  title: string;
  /** Тип инфраструктуры **/
  type: EInfrastructureType;
  /** Цвет иконки **/
  iconColor?: string;
  /** Цвет фона иконки **/
  iconBackground?: string;
}

export enum EInfrastructureType {
  /** Кафе **/
  Cafe = 'cafe',
  /** Поликлиники **/
  Clinic = 'clinic',
  /** Продуктовые магазины **/
  Grocery = 'grocery',
  /** Метро **/
  Metro = 'metro',
  /** Детские сады **/
  Nursery = 'nursery',
  /** Школы **/
  School = 'school',
  /** Похожие объявления */
  SimilarObjects = 'similar_objects',
  /** Похожие ЖК */
  SimilarNewbuildings = 'similar_newbuildings',
  /**
   * основной объект, вокруг которого показывается инфраструктура
   */
  Main = 'main',
}

export type TRegularInfrastructureType =
  /** Основной объект, вокруг которого показывается инфраструктура **/
  | 'main'
  /** Кафе **/
  | 'cafe'
  /** Поликлиники **/
  | 'clinic'
  /** Продуктовые магазины **/
  | 'grocery'
  /** Метро **/
  | 'metro'
  /** Детские сады **/
  | 'nursery'
  /** Школы **/
  | 'school'
  /** Похожие объявления */
  | 'similar_objects'
  /** Похожие ЖК */
  | 'similar_newbuildings';

export interface IInfrastructureItem {
  /** Координаты пина на карте **/
  coordinates: ICoordinates;
  /** id **/
  id: string;
  /** Время пешком до объекта **/
  timeWalk?: number;
  /** Название объекта **/
  title?: string;
  /** Тип объекта инфраструктуры **/
  type: TRegularInfrastructureType;
  /** Дистанция */
  distance?: string;
  /** Адрес */
  address?: string | null;
}

export interface IUndergroundInfrastructureItem extends IInfrastructureItem {
  type: 'metro';
  line?: {
    /** Цвет линии для метро. */
    color: string;
  };
}

export interface ISimilarObjectsItem extends IInfrastructureItem {
  type: 'similar_objects';
  offers: {
    count: number;
    minPrice: number;
    link: string;
    ids: string[];
  };
}

export interface ISimilarNewbuildingItem extends IInfrastructureItem {
  type: 'similar_newbuildings';
  imageUrl: string | null;
  minPrice: string | null;
  modelVersion: string;
  name: string;
  newbuildingId: number;
  roomsSquareMeter: string | null;
  url: string;
}

export type TInfrastructureItem =
  | IInfrastructureItem
  | IUndergroundInfrastructureItem
  | ISimilarObjectsItem
  | ISimilarNewbuildingItem
  | IFutureInfrastructureItem
  | IFutureRoad;

export enum EInfrastructureView {
  ExistingObject = 'existingObject',
  FutureObject = 'futureObject',
}

export interface IInfrastructureData {
  geoObjectsData: Array<{
    geoObject: IMapGeoObject;
    infrastructureItem: TInfrastructureItem | null;
  }>;
  futureTypes: (TRegularInfrastructureType | TFutureRoad)[];
}

interface IInfrastructureState {
  data: IGetInfrastructureV3ResponseSchema | null;
  status: TAsyncThunkStatus;
  activeTypes: string[];
  checkedType: string | null;
}

interface IInfrastructureStateSuccess extends IInfrastructureState {
  status: 'fulfilled';
  data: IGetInfrastructureV3ResponseSchema;
}

export type TInfrastructureState = IInfrastructureState | IInfrastructureStateSuccess;

export type ISetInfrastrutureTypesPayload = Pick<IInfrastructureState, 'checkedType' | 'activeTypes'>;

export interface IInfrastructure {
  regularInfrastructureItems: TInfrastructureItem[];
  transportAccessibilityInfrastructure?: ITAInfrastructure;
}
