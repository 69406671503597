import { IMapCoordinates } from '@cian/frontend-newbuilding-map-component';
import { IGeoObject } from '@cian/frontend-newbuilding-map-component/es/types';

import { IGetInfrastructureV3ResponseSchema } from 'shared/common/repositories/infrastructure-caching/entities/get_infrastructure/GetInfrastructureV3ResponseSchema';
import { getGeoObject } from 'shared/mf-infrastructure/utils/getGeoObject';
import { getPolygonGeoObject } from 'shared/mf-infrastructure/utils/getPolygonGeoObject';

export function transformResultToMainGeoObjects(result: IGetInfrastructureV3ResponseSchema): IGeoObject[] {
  const mainObjects = [
    getGeoObject({
      type: 'main',
      coordinates: {
        lat: result.center.lat,
        lng: result.center.lng,
      } as IMapCoordinates,
      id: '#main-pin',
      customMainPin: result.customMainPin,
    }),
  ];

  if (result.polygon) {
    return mainObjects.concat([
      getPolygonGeoObject({
        name: 'main',
        coordinates: result.polygon,
        id: '#main-polygon',
      }),
    ]);
  }

  return mainObjects;
}
