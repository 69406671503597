import * as React from 'react';
import { useState } from 'react';

import { IAnalyticsParams } from './useAnalyticsParams';
import { trackFullscreenClose, trackFullscreenOpen } from '../tracking';

interface IUseFullscreenProps {
  isFullscreenOpen: boolean;
  handleFullscreenEnter: () => void;
  handleFullscreenExit: () => void;
}

export const useFullscreenProps = (analyticsParams: IAnalyticsParams): IUseFullscreenProps => {
  const [isFullscreenOpen, setFullscreenOpen] = useState(false);

  const handleFullscreenEnter = React.useCallback(() => {
    setFullscreenOpen(true);

    const { blockType, profile } = analyticsParams;
    trackFullscreenOpen(blockType, profile);
  }, [analyticsParams]);

  const handleFullscreenExit = React.useCallback(() => {
    setFullscreenOpen(false);

    const { blockType, profile } = analyticsParams;
    trackFullscreenClose(blockType, profile);
  }, [analyticsParams]);

  return {
    isFullscreenOpen,
    handleFullscreenEnter,
    handleFullscreenExit,
  };
};
